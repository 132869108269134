.main-login {
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
    background: linear-gradient(to right, rgb(223, 221, 221) 50%, #4dd6b6 50%);
}
.main-login .login-contain {
    height: 75vh;
    width: 70%;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(236, 236, 236, 0.4));
    border-radius: 1rem;
    margin: 6.2% auto;
    box-shadow: 0.5rem 0.5rem 2rem -1rem rgb(58, 57, 57);
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-login .login-contain .left-side .footer{
    margin: 0 auto;
    zoom: 0.85;
}
.main-login .login-contain .left-side .footer a{
    text-decoration: none;
    color: #4dd6b6;
    transition: all 0.3s ease;
}
.main-login .login-contain .left-side .footer a:hover{
    transform: translateY(-5px);
    color: gray;
}
.main-login .login-contain .left-side, .main-login .login-contain .right-side {
    margin: 2rem 2rem;
    box-shadow: 0.5rem 0.5rem 2rem -1rem rgb(58, 57, 57);
    height: 90%;
    width: 100%;
}
.main-login .login-contain .left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.main-login .login-contain .left-side .img-class{
    height: 6rem;
    margin: 0.5rem auto;
    margin-bottom: 2rem;
}
.main-login .login-contain .left-side .img-class #img-id{
    height: 100%;
    object-fit: cover;
    margin: -5em auto;
}
.main-login .login-contain .left-side label {
    display: block;
    width: 78%;
    margin: auto;
    font-size: small;
    color: gray;
    margin-bottom: 0.5rem;
}
.main-login .login-contain .left-side input {
    border-radius: 0.2rem;
    display: block;
    border: none;
    height: 2rem;
    width: 78%;
    margin: auto;
    margin-bottom: 1.5rem;
    padding: 0.2rem 1rem;
    font-size: small;
    color: gray;
    transition: all 0.1s ease-in-out;
}
.main-login .login-contain .left-side input:focus {
    outline: none;
    border: none;
}
.main-login .login-contain .left-side input:hover {
    outline: 0.5px solid #4dd6b6;
    box-shadow: 2px 2px 9px -2px #4dd6b6;
}

.main-login .login-contain #sub_butt {
    width: 80%;
    background: #4dd6b6;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: all 0.2 ease;
    border-radius: 0.2rem;
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
}
.main-login .login-contain #sub_butt:hover {
    box-shadow: 4px 3px 9px -1px gray;
    transform: translateY(-5px);
}
.main-login .login-contain .right-side .welcomeNote h2{
        margin-left: 1rem;
        color: black;
        float: left;
        margin-bottom: 0.8rem;
        padding: 2.5rem 1rem;
    
}
.main-login .login-contain .right-side .welcomeImg {
    height: 50%;
    width: 80%;
    margin: 0rem auto;
}
.main-login .login-contain .right-side .welcomeImg #wel-img-id {
    width: 100%;
    object-fit: cover;
}