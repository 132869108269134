.newletter {
    background-color: #1eb2a6;
    padding: 50px 0;
    color: #fff;
}
.newletter .right, .newletter .left {
    padding-right: 20px;
}
.newletter h1 {
    color: #fff;
    font-size: 29.44px;
    font-weight: 500;
    margin: 5px 0;
}
.newletter .right {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    padding-left: 120px;
}
.newletter input {
    width: 90%;
    padding: 20px;
    border: none;
    outline: none;
    height: 30px;
}
.newletter i {
    padding: 12px;
    color: #1eb2a6;
    background-color: #fff;
}
footer {
    background-color: #eeeeee;
}
footer .container {
    display: grid;
    grid-template-columns: 5fr 1.5fr 3fr 4fr;
    grid-gap: 30px;
}
@media screen and (max-width: 768px) {
    footer .container {
        grid-template-columns: repeat(2, 1fr);
    }
}
footer .logo span {
    color: #1eb2a6;
    font-size: 14px;
}
footer .logo p {
    color: grey;
    margin: 30px 0 15px 0;
}
footer .logo .icon {
    background-color: #1eb2a6;
    color: white;
    margin-right: 10px;
}
footer h3 {
    margin-bottom: 40px;
    font-weight: 500;
}
footer ul li {
    margin-bottom: 20px;
    color: #000000;
}
footer .link li::before {
    content: "⟶";
    color: #1eb2a6;
    margin-right: 5px;
}

footer h4 {
    font-weight: 400;
    margin-top: 5px;
}
footer .last ul li {
    display: flex;
    margin-bottom: 29px;
}
footer .last i {
    width: 60px;
    font-size: 20px;
    color: #1eb2a6;
}
.legal {
    text-align: center;
    padding: 20px 0;
    color: grey;
    background-color: #e9e9e9;
}
.legal p {
    font-size: 15px;
}
.legal i {
    color: #1eb2a6;
}
.padding{
    padding-top: 40px;
    padding-bottom: 15px;
    padding-left :15px!important;
      margin-left: 5px!important;
}