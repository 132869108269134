.aboutHome {
    height: auto;

  }
  .aboutHome #heading {
    text-align: center;
    padding: 0;
  }
  .aboutHome .left img {
    width: 100%;
    height: 100%;
  }
 
  .aboutHome .items {
    display: inline;/*******/
  }
  .aboutHome .img {
    width:50%;
    font-size: '24px';
  }
  .aboutHome img {
    width: 70px;
    height: 70px;
  }
  .aboutHome .item {
    background-color: #fff;
    margin-top: 25px;
    padding: 20px;
    transition: 0.5s;
    width: 580px; /**/
    height: 150px; /**/
  }
  .aboutHome .item h2 {
    font-size: 22px;
  }
  .aboutHome .item p {
    font-size: 18px; 
    line-height: 1.6; 
    color: #999999;
    text-align: justify;
  }
  .aboutHome .item:hover {
    background-color: #1eb2a6;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  }
  .aboutHome .item:hover p {
    color: #fff;
  }
  /*----------awrapper------------*/
  .awrapper {
    overflow: hidden;
    background-image: url("../../../public/images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 30vh;
    color: #fff;
    text-align: center;
  /*margin: 0;
  padding: 0;
  background-repeat: no-repeat;*/
  }
  .awrapper .box {
    padding: 70px 0;
  }
  .awrapper img {
    margin-right: 30px;
  }
  .awrapper h1 {
    font-size: 50px;
  }
  .awrapper h3 {
    font-size: 20px;
    font-weight: 500;
  }
  /*----------awrapper------------*/
  @media screen and (max-width: 768px) {
    .aboutHome .container {
      flex-direction: column-reverse;
        padding-top: 310px;
    }
    .aboutHome .row {
      width: 100%;
    }
    .awrapper {
      height: 50vh;
    }
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 0.4fr);
    /*grid-gap: 30px;*/
  }