.hero {
    background-image: url("../../../../public/images/bg.png");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    /**/padding-top: 21%;
    /**/padding-right: 117px;
    color: #fff;
}
a{
    text-decoration: none!important
}
.hero #heading h3 {
    color: #fff;
}
.hero #heading {
    text-align: left;
    margin: 0;
    padding: 0;
}
@media screen and (max-width: 768px) {
    .margin {
    height: 40vh;
    }
    .hero {
        background-position: right;
        padding-top: 50%;
        height: 80vh;
    }
    .hero .row {
        width: 100%;
    }
}