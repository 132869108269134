.App {
  text-align: center;
}

.app button {
  padding: 10px 20px;
  background-color: inherit;
  border: 1px solid gray;
}

.app button:hover{
  background-color: gray;
  color: white;
}

.modalContainer {
  z-index: 2;
  position: fixed;
  width: 100vw; 
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modala{
  width: 90%;
  max-width: 500px;
  border: 5px;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.modal__bottom {
  text-align: right;
  margin-top: 10px;
}

.modal__bottom button {
  padding: 6px 10px;
}

.row{
  width: 100%;
}

