.padding{
  padding: 70px 0;
}
.testimonal .items {
  padding: 25px;
  transition: 0.5s;
  text-align: justify;
  margin: 0 auto;
  display: grid;
}
.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h1 {
  font-size: 22px;
  color: #1eb2a6;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 5px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items .name h1:hover {
  color: #fff;
}
.testimonal .items:hover span,.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}