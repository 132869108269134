.back h1{
    text-align: left;
    padding: 0;
    margin: 0;
    /*margin-top: 50px;*/
    margin-left: 108px;
}
.back h2{
    text-align: left;
    padding: 0;
    margin: 0;
    margin-left: 140px;
}
@media screen and (max-width: 768px) {
	.back h1{
		   margin-top: 200px;
  
    margin-left: 50px;
}
 
    .back {
        height: 140vh;
    }
    
}
