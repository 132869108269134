/*--------head------------*/
.head {
    padding: 20px 0;
    color: #fff;
  }
  .logo h1 {
    font-size: 35px;
    font-weight: 900;
  }
  .head .icon {
    margin-left: 10px;
  }
  /*--------head------------*/
  /*--------.headerr------------*/
  .headerr {
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 30px;
  }
  .headerr .nav {
    display: flex !important;
  }
  .headerr li {
    margin-right: 40px;
  }
  .headerr ul {
    padding: 30px 20px;
  }
  .headerr ul li a {
    color: #fff;
    font-weight: 500;
    transition: 0.5s;
  }
  .headerr ul li a:hover {
    color: #1eb2a6;
  }
  .start {
    background-color: #1eb2a6;
    padding: 30px 70px;
    color: #fff;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  }
  
  .toggle {
    display: none;
  }
  /*--------..headerrr------------*/
  @media screen and (max-width: 768px) {
    .start {
      clip-path: none;
    }
    .headerr {
      margin: 0;
      background-color: #1eb2a6;
      position: relative;
    }
    .headerr ul.flexSB {
      display: none;
    }
    .headerr ul li {
      margin-bottom: 20px;
    }
    .toggle {
      display: block;
      background: none;
      color: #fff;
      font-size: 30px;
      position: absolute;
      right: 0;
      top: -20px;
    }
    .mobile-nav {
      position: absolute;
      top: 7vh;
      left: 0;
      width: 100%;
      background-color: #1eb2a6;
    }
  }